import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

const Categories = ({ user }) => {
    const [categories, setCategories] = useState([]); // Changed from customers to categories
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchCategories();

    }, []);
    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!isLoading && categories.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'name', title: 'Name' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.delete-button', function () {
            const categoryId = $(this).data('category-id');
            handleDelete(categoryId);
        });

    }, [categories, isLoading]);
    const dataTableRef = useRef(null);
    const fetchCategories = () => { // Changed from fetchCustomers to fetchCategories
        const token = user.user.user.token;
        axios
            .get(process.env.REACT_APP_API_URL + '/get-categories/' + user.user.user.companyDetails.id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                const fetchedCategories = response.data.categories; // Changed from fetchedCustomers to fetchedCategories
                setCategories(fetchedCategories); // Changed from setCustomers to setCategories
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error); // Changed from 'Error fetching customers' to 'Error fetching categories'
                setIsLoading(false);
            });
    };

    const handleDelete = (categoryId) => {

        Swal.fire({
            title: 'Delete Category',
            text: 'Are you sure you want to delete this category?', // Changed from 'Delete Customer' to 'Delete Category'
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it',
        }).then((result) => {
            if (result.isConfirmed) {
                const token = user.user.user.token;
                axios
                    .delete(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/categories/${categoryId}`, { // Changed from '/customers' to '/categories'
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {
                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                setCategories((prevCategories) => prevCategories.filter((category) => category.id !== categoryId)); // Changed from setCustomers to setCategories
                            });
                        } else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    };


    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Categories</h4> {/* Changed from 'Customer' to 'Category' */}
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">

                                        <Link
                                            to="/categories-add" // Changed from '/customer-add' to '/category-add'
                                            className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Category {/* Changed from 'Add Customer' to 'Add Category' */}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">

                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Category List</h5> {/* Changed from 'Customer List' to 'Category List' */}
                                        </div>
                                        <div className="table-responsive pr-2">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table
                                                    ref={dataTableRef}
                                                    className="table data-table mb-0"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Name</th>
                                                            <th scope="col" className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categories.map((category) => (
                                                            <tr key={category.id}>
                                                                <td>{category.id}</td>
                                                                <td>{category.name}</td>
                                                                <td>
                                                                    <div className="d-flex justify-content-center align-items-center">

                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                            <Link className="" to={`/categories-edit/${category.id}`}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                </svg>
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                            <button className="badge bg-danger delete-button" data-category-id={category.id} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                </svg>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}

                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Categories;
